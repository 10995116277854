.pause-icon {
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.pause-icon:hover,
.bg-icon-pause:hover .pause-icon {
  color: #ffc107;
}

.bg-icon-play:hover .pause-icon {
  color: #28a745;
}

.bg-icon-play:hover {
  background-color: #c3e6cb;
}

.bg-icon-pause:hover {
  background-color: #fdf2cf;
}

.bg-icon-play,
.bg-icon-pause {
  background-color: transparent;
  border-radius: 50px !important;
  padding: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}