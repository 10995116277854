.fc-view-harness-active {
    min-height: 500px;
}

.fc-dayGridMonth-view,
.fc-timeGridWeek-view  {
    overflow-x: auto;
    white-space: nowrap;
}

.fc-timeGridWeek-view .fc-scrollgrid,
.fc-dayGridMonth-view .fc-scrollgrid {
    min-width: 768px;
}

.fc-header-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff; /* Fondo blanco para que sea visible */
    padding: 10px 0;
    border-bottom: 1px solid #ddd; /* Línea divisoria */
  }
  
  /* Estilos para las tarjetas de eventos en dispositivos móviles */
  .fc-event {
    white-space: normal; /* Permite que el texto se ajuste en varias líneas */
    word-wrap: break-word; /* Ajusta el texto largo para que no se desborde */
    min-width: 100px; /* Asegura un tamaño mínimo para las tarjetas */
  }

  @media (max-width: 768px) {
    .fc-event {
      font-size: 12px; /* Ajusta el tamaño de fuente para dispositivos móviles */
      padding: 5px; /* Agrega espacio interno para mejorar la legibilidad */
    }
  
    .fc-daygrid-event {
      max-width: none; /* Permite que las tarjetas se extiendan completamente */
    }
  
    .fc-timegrid-event {
      max-width: none; /* Aplica lo mismo para la vista de semana */
    }
  }
  