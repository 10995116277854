.home-page {
    .section {
        background-color: $dark-primary !important;
    }
}

.service-cont {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
}

.service-cont:hover {
    background: #f1f1f1 !important;
    color: #333;
}
.bg-section-light {
    background-color: $light-300;
}

.auth-wrapper {
    min-height: auto !important;
}

.bg-section-secondary {
    background-color: $dark-secondary;
}

.page-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .breadcrumb {
        margin-bottom: 0;
    }
    @include respond-below(sm) {
        flex-direction: column;
        align-items: self-start;
    }
    .page-title {
        margin-bottom: 10px;
    }
    h3{
        font-weight: $font-weight-bold;
        color: $gray-900;
        font-size: $font-size-18;
        @include respond-below(lg) {
            font-size: $font-size-base;
        }
    }
    h4 {
        font-weight: $font-weight-semibold;
        color: $gray-900;
        font-size: $font-size-18;
        margin-bottom: 5px;
        @include respond-below(lg) {
            font-size: $font-size-18;
        }
    }
    h6 {
        font-size: $font-size-base;
        color: $gray-700;
        font-weight: $font-weight-normal;
        @include respond-below(lg) {
            font-size: $font-size-12;
        }
        @include respond-below(sm) {
            font-size: $font-size-12;
            margin-bottom: 20px;
        }
    }
}
.admin {
    .mobile-view,
        .pc-view {
            transition: max-width 0.3s ease, font-size 0.3s ease;
        }
        .mobile-view {
            max-width: 320px;
            margin: 0 auto;
            font-size: 14px;
        }
        .pc-view {
            max-width: 600px;
            margin: 0 auto;
            font-size: 16px;
        }
        .main-wrapper {
        min-height: auto !important;
        height: auto !important;
    }
    .page-wrapper {
        margin-left: 270px;
        position: relative;
        transition: all 0.4s ease;
        background-color: #fff;
        min-height: unset;
        @media (max-width: 1024px) {
            margin-left: 0;
          }
    }
    .content {
        padding: 20px;
        padding-top: 50px;
        width: 100%;
    }
    .admin-sidebar  {
        background-color: $secondary-600;
        .menu-title h6 {
            color: $secondary-800;
            margin: 0;
            font-size: 13px;
            font-weight: 700;
        }
        .admin-sidebar-menu > ul > li > a.active {
            position: relative;
            color: #fff;
            background-color: $secondary-800;
          }
          .admin-sidebar-menu > ul > li > a.active.subdrop {
            color: #fff;
            background-color: $secondary-800;
          }
          .admin-sidebar-menu > ul > li > a.active:hover {
            color: #fff;
          }
          .admin-sidebar-menu > ul > li > a.active:after {
            background: $secondary-800;
            width: 6px;
            height: 40px;
            left: -10px;
            z-index: 9999;
            position: absolute;
            content: "";
            border-radius: 0 5px 5px 0;
          }
          .admin-sidebar-menu {
            > ul {
              > li {
                > a {
                  &:hover {
                    background-color: $secondary-800;
                    color: #fff;
                  }
                  &.active {
                    &:hover {
                      background-color: $secondary-800;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
    }

    .form-control {
        &:focus {
        border-color: $secondary;
        box-shadow: none;
        border-color: $secondary;
        }
    }

    .dropdown-heads > a:hover {
        background: $secondary-400 !important;
        color: $white !important;
      }
}

.provider-page {
    .page-wrapper {
        margin-left: 216px;
        padding-top: 48px;
        @include respond-below(lg) {
            margin-left: 0;
        }
    }
    .content {
        padding: 20px;
    }
}
.content {
    &.content-two {
        padding-bottom: 36px;
    }
}