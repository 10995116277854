.overlay-freeze {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
}

.overlay-freeze.visible {
    opacity: 1;
    pointer-events: auto;
}

.overlay-freeze.hidden {
    opacity: 0;
    pointer-events: none;
}

.selected-card {
    z-index: 20;
    position: relative;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.class-card {
    transition: transform 0.3s ease-in-out;
}

.class-card:hover:not(.selected-card) {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.class-card.touch-active:not(.selected-card) {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.freeze-card {
    transition: transform 0.3s ease-in-out;
}

.freeze-card:hover:not(.selected-card) {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.freeze-card.touch-active:not(.selected-card) {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
}

.floating-table {
    position: absolute;
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 30;
    width: 80%;
    max-width: 800px;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.floating-table.visible {
    opacity: 1;
}

.floating-table.hidden {
    opacity: 0;
}

.floating-table.below {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
}

.floating-table.above {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -10px);
}

.pause-page {
    stroke-width: 2.5; /* Aumenta el grosor del trazo */
}

